import { useMemo } from 'react';
import { Box, Typography } from '@mui/material';
import QuizHeader from '../../common/QuizHeader';
import QuizQuestion from '../../common/QuizQuestion';
import QuizContainer from '../../common/QuizContainer';
import QuizButton, { ButtonType } from '../../common/QuizButton';
import { useQuizState } from '@providers/QuizProvider/QuizProvider.hooks';
import QuizStepBackgroundWrapper from '../../common/QuizStepBackgroundWrapper';
import { useNavigate } from 'react-router-dom';
import { Routes } from '../../../App';
import { getPathWithSearchParams } from '@utils/ulr';

function Step12({
  stepNumber = 12,
  stepsNumber = 19,
  backToMain = false,
}: {
  stepNumber?: number;
  stepsNumber?: number;
  backToMain?: boolean;
}) {
  const { setStep, setQuizData, quizData } = useQuizState();
  const navigate = useNavigate();

  const isAgeVerified = useMemo(
    () => (quizData.age ? Number(quizData.age) >= 19 : null),
    [quizData.age]
  );

  return (
    <QuizStepBackgroundWrapper>
      <QuizHeader
        onBack={() => {
          if (backToMain) {
            navigate(getPathWithSearchParams(Routes.MAIN));
          } else {
            setStep((prev) => prev - 1);
          }
        }}
        stepNumber={stepNumber}
        stepsNumber={stepsNumber}
      ></QuizHeader>
      <QuizQuestion text="What’s your age?"></QuizQuestion>
      <QuizContainer>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '15px',
            marginBottom: '30px',
            padding: '0 20px',
          }}
        >
          <Box
            sx={{
              width: '100%',
              maxWidth: '100%',
              '& input': {
                width: 'fill-available',
                textAlign: 'center',
                background: 'transparent',
                border: 'none',
                fontFamily: 'Avenir600',
                fontSize: '64px',
                lineHeight: 'normal',
                color: '#282624',
                '&:focus-visible': {
                  border: 'none',
                  outline: 0,
                },
                '::placeholder': {
                  opacity: 0.3,
                },
              },
            }}
          >
            <input
              placeholder="0"
              type="number"
              min={0}
              onChange={(e) => {
                if (
                  Number(e.target.value) >= 0 &&
                  Number(e.target.value) < 100
                ) {
                  setQuizData((prev) => ({
                    ...prev,
                    age: e.target.value,
                  }));
                }
              }}
              value={quizData.age}
            ></input>
          </Box>
          <QuizButton
            disabled={!isAgeVerified || quizData.age === undefined}
            onClick={() => {
              if (!isAgeVerified) return;
              setStep((prev) => prev + 1);
            }}
            variant={ButtonType.ORANGE}
            text="Continue"
          ></QuizButton>
          {!isAgeVerified && typeof isAgeVerified === 'boolean' && (
            <Typography
              sx={{
                fontFamily: 'Avenir400',
                fontSize: '14px',
                lineHeight: 'normal',
                color: '#FF344C',
                textAlign: 'center',
              }}
            >
              Sorry but we cannot proceed the Quiz with users under 19
            </Typography>
          )}
        </Box>
      </QuizContainer>
    </QuizStepBackgroundWrapper>
  );
}

export default Step12;
