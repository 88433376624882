import {
  useState,
  ReactNode,
  createContext,
  FC,
  useEffect,
  useRef,
  FormEvent,
} from 'react';

import { PaymentContextType } from './PaymentProvider.types';
import { useRecurly } from '@recurly/react-recurly';

import { ApplePayError, ApplePayInstance } from '@recurly/recurly-js';
import {
  activateSubscriptionRecurly,
  createSubscriptionNoUser,
  createSubscriptionRecurly,
  trackEvent,
} from '@api/requests';
import { redirectBackAfterPayment } from '@utils/payment';
import { Routes } from '../../App';
import { useQuizState } from '../QuizProvider/QuizProvider.hooks';
import { PaymentMethodType } from '../QuizProvider/QuizProvider.types';
import { useNavigate } from 'react-router-dom';
import { getParamByKey } from '@utils/common';
import { getPathWithSearchParams } from '@utils/ulr';
import useUpdateEffect from '../../hooks/useUpdateEffect';
import { useUser } from '../UserProvider';
import {
  getParamsFromSessionStorage,
  setParamsToSessionStorage,
} from '@utils/storage';
import {
  getActiveSubscriptionConfig,
  removeRedirectParamsFromQuery,
} from '@utils/payment';
import { convertStringToBoolean, getAttribution } from '@utils/attribution';
import {
  isActiveSubscribeRecurlySuccessResponse,
  isSubscribeRecurlyErrorResponse,
  isSubscribeRecurlySecure3dResponse,
  isSubscribeRecurlySuccessResponse,
} from '@models/recurly';
import { usePaymentConfig } from '@providers/PaymentConfigProvider';
import { PixelEvents } from '@constants/events';

export const PaymentContext = createContext<PaymentContextType | null>(null);

export const PaymentProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const trickEvent = convertStringToBoolean(
    getParamByKey('event_trick') ?? 'false'
  );

  const trickFinalValue = trickEvent
    ? PixelEvents.Subscribe
    : PixelEvents.Purchase;

  const { setQuizData, quizData, userLocation } = useQuizState();

  const { paymentConfig, selectedPlan } = usePaymentConfig();

  const [isReadyAppleButton, setReadyAppleButton] = useState(false);
  const [applePay, setApplePay] = useState<ApplePayInstance | null>(null);
  const recurly = useRecurly();
  const formRef = useRef<HTMLFormElement>(null);
  const [paymentFormError, setPaymentFormError] = useState<string | null>(null);
  const [applePayError, setApplePayError] = useState<ApplePayError | null>(
    null
  );

  const [error, setError] = useState<null | string>(null);

  const [isPaymentLoading, setPaymentLoading] = useState(false);

  const [subscriptionUserEmail, setSubscriptionUserEmail] = useState<
    string | null
  >(null);

  const [subscriptionId, setSubscriptionId] = useState('');

  const [loading, setLoading] = useState(false);

  const activateSubscriptionConfig = getActiveSubscriptionConfig();

  const recurlySecureRef = useRef(null);

  const navigate = useNavigate();

  const { user } = useUser();

  useEffect(() => {
    if (selectedPlan) {
      setApplePay(
        recurly.ApplePay({
          country: 'US',
          currency: 'USD',
          label: selectedPlan.name_full ?? '',
          total: String(selectedPlan?.trial_sum ?? selectedPlan?.sum),
        })
      );
    }
  }, [selectedPlan]);

  useEffect(() => {
    applePay?.ready(() => {
      setReadyAppleButton(true);
      setQuizData((prev) => ({
        ...prev,
        selectedPaymentMethod: PaymentMethodType.APPLE,
      }));
    });
  }, [applePay]);

  useEffect(() => {
    if (applePay && subscriptionUserEmail !== null) {
      applePay.on('error', function (err) {
        console.error('Apple Pay error', err);
        setApplePayError(err);
        setPaymentLoading(false);
      });

      applePay.on('token', function (token) {
        if (!token) {
          console.error('token is not defined');
        } else {
          createSubscriptionNoUser({
            url: String(
              paymentConfig?.recurly?.uri ?? process.env.REACT_APP_RECURLY_URI
            ),
            email: subscriptionUserEmail,
            plan_id: selectedPlan?.id,
            token_id: token.id,
            attribution: getAttribution({
              ip: userLocation?.ip ?? '',
              country: userLocation?.country ?? '',
            }),
          })
            .then((response) => {
              if (isSubscribeRecurlyErrorResponse(response)) {
                console.error(response.error);
                setApplePayError({ message: response.error } as ApplePayError);
                setPaymentLoading(false);
              } else if (!isSubscribeRecurlySuccessResponse(response)) {
                console.error(response);
              } else {
                // @ts-ignore
                if (window.fbq) {
                  // @ts-ignore
                  fbq('track', 'Purchase');
                }

                if (trickFinalValue) {
                  trackEvent(trickFinalValue, userLocation).catch(
                    console.error
                  );
                }

                setSubscriptionId(response.id);

                setParamsToSessionStorage({
                  url: paymentConfig?.recurly?.uri ?? null,
                  selectedPlanId: selectedPlan?.id ?? '',
                  subscriptionId: response.id,
                });

                if (getParamByKey('redirectDomain')) {
                  redirectBackAfterPayment(
                    response.id,
                    subscriptionUserEmail,
                    selectedPlan?.id ?? '',
                    paymentConfig?.recurly?.uri ?? ''
                  );
                } else {
                  navigate(getPathWithSearchParams(Routes.SIGN_IN));
                }
              }
            })
            .catch((response) => {
              if (typeof response.error === 'string') {
                setApplePayError(response.error);
              }
            })
            .finally(() => {
              setPaymentLoading(false);
            });
        }
      });
    }
  }, [applePay, subscriptionUserEmail, paymentConfig?.recurly?.uri]);

  useUpdateEffect(() => {
    const appleToken = user?.isAppleAuth
      ? user?.providerDataId ?? user?.uid
      : user?.uid;

    const sessionPaymentParams = getParamsFromSessionStorage([
      'url',
      'subscriptionId',
      'selectedPlanId',
    ]);

    let currentSubscriptionId = '';
    let selectedPlanId = '';
    let recurlyUri = '';

    const redirectPlanId = getParamByKey('redirectPlanId');
    const redirectTokenId = getParamByKey('redirectTokenId');
    const redirectRecurlyUri = getParamByKey('redirectRecurlyUri');

    if (sessionPaymentParams.url) {
      recurlyUri = sessionPaymentParams.url;
    } else if (redirectRecurlyUri) {
      recurlyUri = redirectRecurlyUri;
    } else {
      recurlyUri =
        paymentConfig?.recurly?.uri ?? process.env.REACT_APP_RECURLY_URI ?? '';
    }

    if (subscriptionId) {
      currentSubscriptionId = subscriptionId;
    } else if (sessionPaymentParams.subscriptionId) {
      currentSubscriptionId = sessionPaymentParams.subscriptionId;
    } else {
      currentSubscriptionId = redirectTokenId ?? '';
    }

    if (sessionPaymentParams.selectedPlanId) {
      selectedPlanId = sessionPaymentParams.selectedPlanId;
    } else if (redirectPlanId) {
      selectedPlanId = redirectPlanId;
    }

    if (activateSubscriptionConfig && user) {
      setLoading(true);
      setError(null);

      activateSubscriptionRecurly({
        url: recurlyUri,
        email: user?.email ?? quizData.email,
        firebase_id: !user?.isAppleAuth ? appleToken : undefined,
        apple_token: user?.isAppleAuth ? appleToken : undefined,
        account_code: activateSubscriptionConfig.account_code,
      })
        .then(async (response) => {
          if (isActiveSubscribeRecurlySuccessResponse(response)) {
            navigate(getPathWithSearchParams(Routes.DOWNLOAD_APP));
          } else if (isSubscribeRecurlyErrorResponse(response)) {
            console.error(response);
            setError(response.error);
          } else {
            setError('Something went wrong');
          }
        })
        .catch((err) => {
          console.error(err);

          if (isSubscribeRecurlyErrorResponse(err)) {
            setError(err.error);
          }
        })
        .finally(() => {
          setLoading(false);
        });
    } else if (user && currentSubscriptionId !== '') {
      setLoading(true);
      setError(null);

      createSubscriptionRecurly({
        url: recurlyUri,
        plan_id: selectedPlanId,
        subscription_id: currentSubscriptionId,
        email: user?.email ?? quizData.email,
        firebase_id: !user?.isAppleAuth ? appleToken : undefined,
        apple_token: user?.isAppleAuth ? appleToken : undefined,
        attribution: getAttribution({
          ip: userLocation?.ip ?? '',
          country: userLocation?.country ?? '',
        }),
      })
        .then(async (response) => {
          if (isSubscribeRecurlySecure3dResponse(response)) {
            /** only for cards **/
            const risk = recurly.Risk();

            const threeDSecure = risk.ThreeDSecure({
              actionTokenId: response.actionTokenId,
            });

            threeDSecure.on('token', function (token) {
              createSubscriptionWithCardToken(token.id).then(() => {
                setPaymentLoading(false);
              });
            });

            threeDSecure.on('error', function (error) {
              console.error('error', error);
            });

            // @ts-ignore
            threeDSecure.attach(recurlySecureRef.current);

            return;
          } else if (isSubscribeRecurlySuccessResponse(response)) {
            setSubscriptionId('');

            setParamsToSessionStorage({
              subscriptionId: '',
              selectedPlanId: '',
              url: '',
            });

            navigate(getPathWithSearchParams(Routes.DOWNLOAD_APP));
          } else if (isSubscribeRecurlyErrorResponse(response)) {
            console.error(response.error);
            setError(response.error);
          }
        })
        .catch((err) => {
          if (isSubscribeRecurlyErrorResponse(err)) {
            setError(err.error);
          }
        })
        .finally(() => {
          setLoading(false);
          removeRedirectParamsFromQuery();
        });
    }
  }, [user, subscriptionId]);

  function handlePayByApple() {
    if (applePay) {
      setSubscriptionUserEmail(quizData.email);
      applePay.begin(() => {
        setPaymentLoading(true);
      });
    }
  }

  const createSubscriptionWithCardToken = (tokenId: string) =>
    selectedPlan && !activateSubscriptionConfig && !subscriptionId.trim()
      ? createSubscriptionNoUser({
          url: String(
            paymentConfig?.recurly?.uri ?? process.env.REACT_APP_RECURLY_URI
          ),
          email: getParamByKey('redirectUserEmail') ?? quizData.email,
          plan_id: selectedPlan?.id,
          token_id: tokenId,
          attribution: getAttribution({
            ip: userLocation?.ip ?? '',
            country: userLocation?.country ?? '',
          }),
        })
          .then((response) => {
            if (isSubscribeRecurlyErrorResponse(response)) {
              console.error(response.error);
              setPaymentFormError(response.error);
              setPaymentLoading(false);
            } else if (!isSubscribeRecurlySuccessResponse(response)) {
              console.error(response);
            } else {
              // @ts-ignore
              if (window.fbq) {
                // @ts-ignore
                fbq('track', 'Purchase');
              }

              if (trickFinalValue) {
                trackEvent(trickFinalValue, userLocation).catch(console.error);
              }

              setSubscriptionId(response.id);

              setParamsToSessionStorage({
                url: paymentConfig?.recurly?.uri ?? null,
                selectedPlanId: selectedPlan.id,
                subscriptionId: response.id,
                attribution: JSON.stringify(
                  getAttribution({
                    country: userLocation?.country ?? '',
                    ip: userLocation?.ip ?? '',
                  })
                ),
              });

              if (getParamByKey('redirectDomain')) {
                redirectBackAfterPayment(
                  response.id,
                  quizData.email,
                  selectedPlan?.id ?? '',
                  paymentConfig?.recurly?.uri ?? ''
                );
              } else {
                navigate(getPathWithSearchParams(Routes.SIGN_IN));
              }
            }
          })
          .catch((response) => {
            if (typeof response.error === 'string') {
              setPaymentFormError(response.error);
            }
          })
      : Promise.resolve();

  function handleSubmitForm(event: FormEvent<HTMLFormElement>) {
    event.preventDefault();
    if (formRef.current) {
      setPaymentLoading(true);
      recurly.token(formRef.current, (err, token) => {
        if (err) {
          console.error(err);
          setPaymentFormError(err.message);
          setPaymentLoading(false);
        } else {
          createSubscriptionWithCardToken(token.id).then(() => {
            setPaymentLoading(false);
          });
        }
      });
    }
  }

  const value: PaymentContextType = {
    isReadyAppleButton,
    handlePayByApple,
    handleSubmitForm,
    paymentFormError,
    applePayError,
    formRef,
    isPaymentLoading,
    loading,
    paymentConfig,
    error,
  };

  return (
    <PaymentContext.Provider value={value}>{children}</PaymentContext.Provider>
  );
};
