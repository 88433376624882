import { Box, Link, Typography } from '@mui/material';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { trackEvent } from '@api/requests';
import { Routes } from '../../App';
import bg from '../../assets/images/main/bg.webp';
import logo from '../../assets/images/main/logo.svg';
import { useQuizState } from '@providers/QuizProvider/QuizProvider.hooks';
import QuizButton, { ButtonType } from '../common/QuizButton';
import { getPathWithSearchParams } from '@utils/ulr';

function Main() {
  const navigate = useNavigate();
  const { userLocation } = useQuizState();

  useEffect(() => {
    trackEvent(`Intro shown`, userLocation);
  }, []);

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        backgroundColor: '#FCF4EF',
        width: '100%',
        height: '100%',
        minHeight: '100vh',
      }}
    >
      <Box
        sx={{
          width: '100%',
          maxWidth: '460px',
          backgroundImage: `url(${bg})`,
          backgroundPosition: 'center 300px',
          backgroundRepeat: 'no-repeat',
          backgroundSize: '100%',
          padding: '30px 20px 10px',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            justifyContent: 'space-between',
          }}
        >
          <Box>
            <Box
              sx={{
                display: 'flex',
                gap: '8px',
                justifyContent: 'center',
                alignItems: 'center',
                marginBottom: '20px',
              }}
            >
              <img width={'26px'} height={'26px'} src={logo} alt="logo"></img>
              <Typography
                sx={{
                  fontFamily: 'Avenir600',
                  fontSize: '14px',
                  userSelect: 'none',
                  color: '#282624',
                }}
              >
                Refuse
              </Typography>
            </Box>
            <Typography
              sx={{
                fontFamily: 'Avenir600',
                fontSize: '24px',
                color: '#282624',
                textAlign: 'center',
                userSelect: 'none',
                marginBottom: '10px',
                '.orange': {
                  color: '#ED744E',
                },
              }}
            >
              Redefine Your Relationship
              <br /> with
              <span className="orange"> Alcohol</span>
            </Typography>
            <Typography
              sx={{
                textAlign: 'center',
                fontFamily: 'Avenir400',
                color: '#908C89',
                marginBottom: '20px',
              }}
            >
              This assessment has been reviewed and approved by a mental health
              professional.
            </Typography>

            <QuizButton
              onClick={() => {
                navigate(getPathWithSearchParams(Routes.QUIZ));
              }}
              variant={ButtonType.ORANGE}
              text="Start the Quiz"
            />
          </Box>
          <Box>
            <Box
              sx={{
                height: '1px',
                width: '100%',
                background: '#FFF',
                opacity: 0.2,
                margin: '10px 0',
              }}
            ></Box>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-around',
                '& .link': {
                  fontFamily: 'Avenir400',
                  fontSize: '14px',
                  color: '#fff',
                  textDecoration: 'none',
                  transition: 'all 0.3s ease',
                  whiteSpace: 'nowrap',
                  '&:hover': {
                    opacity: 0.8,
                  },
                },
              }}
            >
              <Link target="_blank" href={Routes.PRIVACY} className="link">
                Privacy Policy
              </Link>
              <Link target="_blank" href={Routes.TERMS} className="link">
                Terms of Use
              </Link>
              <Link
                target="_blank"
                href={Routes.COOKIE_POLICY}
                className="link"
              >
                Cookie Policy
              </Link>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default Main;
