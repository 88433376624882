import React, { useEffect, useMemo } from 'react';
import { Box } from '@mui/material';
import {
  Step1,
  Step2,
  Step3,
  Step4,
  Step5,
  Summary1,
  Step6,
  Step7,
  Step8,
  Step9,
  Step10,
  Step11,
  Step12,
  Step13,
  Step14,
  Step15,
  Step16,
  Step17,
  Step18,
  Step19,
  Processing,
  Email,
} from './Steps';
import { useQuizState } from '@providers/QuizProvider/QuizProvider.hooks';

import { trackEvent } from '@api/requests';
import Goal from './Steps/Goal';
import Habits from './Steps/Habits';
import Chart from './Steps/Chart';
import Summary6 from './Steps/Summary6';

const getDefaultStep = (stepNumber: number) => {
  window.scrollTo(0, 0);

  switch (stepNumber) {
    case 1: {
      return <Step1 />;
    }
    case 2: {
      return <Step2 />;
    }
    case 3: {
      return <Step3 />;
    }
    case 4: {
      return <Summary1 />;
    }
    case 5: {
      return <Step4 />;
    }
    case 6: {
      return <Goal />;
    }
    case 7: {
      return <Habits />;
    }
    case 8: {
      return <Step5 />;
    }
    case 9: {
      return <Step6 />;
    }
    case 10: {
      return <Step7 />;
    }
    case 11: {
      return <Step8 />;
    }
    case 12: {
      return <Step9 />;
    }
    case 13: {
      return <Step10 />;
    }
    case 14: {
      return <Chart />;
    }
    case 15: {
      return <Step11 />;
    }
    case 16: {
      return <Step12 />;
    }
    case 17: {
      return <Step13 />;
    }
    case 18: {
      return <Step14 />;
    }
    case 19: {
      return <Step15 />;
    }
    case 20: {
      return <Step16 />;
    }
    case 21: {
      return <Step17 />;
    }
    case 22: {
      return <Step18 />;
    }
    case 23: {
      return <Step19 />;
    }
    case 24: {
      return <Summary6 />;
    }
    case 25: {
      return <Processing />;
    }
    case 26: {
      return <Email />;
    }
  }
};

const getShortStep = (stepNumber: number, setStep: (arg: number) => void) => {
  window.scrollTo(0, 0);

  switch (stepNumber) {
    case 1: {
      return <Step1 stepNumber={1} stepsNumber={4} />;
    }
    case 2: {
      return <Step12 stepNumber={2} stepsNumber={4} />;
    }
    case 3: {
      return <Step2 stepNumber={3} stepsNumber={4} />;
    }
    case 4: {
      return <Step3 stepNumber={4} stepsNumber={4} />;
    }
    case 5: {
      return <Email />;
    }
  }
};

function Quiz() {
  const { step, userLocation, setStep } = useQuizState();
  const mode = new URLSearchParams(window.location.search).get('mode');
  const steps = useMemo(() => {
    switch (mode) {
      case 'short': {
        return getShortStep(step, setStep);
      }
      default: {
        return getDefaultStep(step);
      }
    }
  }, [step, mode, setStep]);

  const getScreenName = (mode: string | null, step: number) => {
    switch (mode) {
      case 'short': {
        if (step === 6) return 'Email screen shown';
        if (step === 7) return 'Manual screen shown';
        return `Step ${step} shown`;
      }
      default: {
        if (step === 29) return 'Email screen shown';
        if (step === 30) return 'Manual screen shown';
        return `Step ${step} shown`;
      }
    }
  };

  useEffect(() => {
    trackEvent(getScreenName(mode, step), userLocation);
  }, [step, mode]);

  return (
    <Box
      sx={{
        minHeight: '100dvh',
        height: '100%',
      }}
    >
      {steps}
      <Box
        sx={{
          display: 'none',
        }}
      ></Box>
    </Box>
  );
}

export default Quiz;
