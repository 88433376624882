import { APP_CONSTANTS } from '../../utils/constants';
import { Routes } from '../../App';
import { getHost } from "@services/hostUtils";

const CookiePolicy = (): JSX.Element => {
  return (
    <div style={{ padding: '50px' }}>
      <h1>Cookie policy of Refuse Web and Mobile Application</h1>
      <h2>Date: August 7, 2024</h2>
      This cookie policy explains what cookies are, how we use them and what
      benefits they allow while using our website. This is to be read alongside
      our <a href={`${Routes.PRIVACY}`}>privacy policy</a> which can be found
      here and explains how we use personal information.
      <br />
      <br />
      Your consent applies to the following domains:
      https://{getHost()}/refuse/.
      <br />
      <h3>General description of cookies</h3>
      A cookie is a small file of letters and numbers that we store on your
      browser or the hard drive of your computer if you agree. Cookies contain
      information that is transferred to your computer's hard drive. Our website
      uses cookies to distinguish you from other users of our website. This
      helps us to provide you with a good experience when you browse our website
      and also allows us to improve our site.
      <br /> Although this notice refers to the general term ‘cookie’, it also
      applies to similar tracking technologies that we may use, such as ‘web
      beacons’, ‘clear GIFs’ and ‘pixel tags’.
      <br /> You can find out more about cookies at www.allaboutcookies.org.
      <br /> We use cookies on many of the websites of our corporate group.
      Cookies are small text files that are stored on your electronic device
      (e.g. computer, smartphone, game console) when you visit the websites and
      enable the reassignment of your browser.
      <br /> For this, we not only use our own cookies (first-party cookies),
      but also the professional services of third-party providers who use
      cookies on our websites (third-party cookies). You can find out which
      third party providers these are and what exactly they do under the section
      on third-party providers.
      <br /> We divide cookies into four categories: essential cookies,
      analytical cookies, marketing cookies and functional cookies. You will
      find a more detailed description and explanation of these categories and
      the associated data processing under the item “Description of Categories”.
      <br /> You will find an overview of the cookies we use (first and
      third-party cookies) and the duration of storage under the item
      "Third-Party Providers".
      <br /> You can generally prevent or stop cookies from being stored using
      an appropriate setting in your browser software. This may mean that not
      all of the functions of our websites can be used to their full extent,
      however.
      <br /> When you visit our websites, you will receive information on the
      use and application of cookies via the cookie banner. In this cookie
      banner, you may specify which cookies you agree to. You may change or
      revoke your consent here at any time. Your revocation of consent does not
      affect the lawfulness of the processing carried out on the basis of your
      consent up to the time of revocation.
      <br /> In the event that direct or indirect references are made to
      external websites, the respective operator of the website is responsible
      for the legally compliant use of the cookies.
      <h4>Description of categories</h4>
      <h4> Necessary cookies </h4>
      These cookies are absolutely necessary in order to enable the essential
      functions and security settings on our website. For this purpose,
      anonymised evaluations are carried out and navigation and visitor settings
      of user behavior are stored.
      <h4>Analytical cookies </h4>
      These cookies help us to collect information about traffic to our Site and
      how users use our Site, but this information doesn’t include personal
      identify information about user, the information collected is aggregated
      and anonymous
      <h4> Marketing cookies </h4>
      We use marketing cookies to display personalized and interest-based
      advertising for you. When you visit another website afterward, these
      cookies can be recognised by your browser. Based on this, selected offers
      and advertisements can be displayed to you. This procedure is also known
      as retargeting.
      <h4> Functional cookies: </h4>
      Functional cookies and similar technologies enable us to display certain
      functions of our website (e.g. videos) or to ensure enhanced
      functionality. This also enables the information provided, such as the
      username or language selection, to be stored and improved and personalized
      functions to be offered to the user on the basis of this. These cookies
      merely collect and store anonymised information.
      <h4> Social Media cookies:</h4>
      These cookies are used when you share information using social media
      sharing. We may use web and mobile analytics and advertising services and
      technologies (such as Google Analytics, Facebook Analytics, Facebook
      Pixel, Firebase, AppStore Analytics, Tenjin, GameAnalytics, AdMob etc.) to
      assist in collecting certain information about the devices or computer you
      use to access the Apps and Sites.
      <br /> <br />
      <b>Essential cookies:</b> to provide you with Services available through
      our Site and to enable you to use some of its features.
      <br />
      <br />
      <b>Functionality cookies: </b>to remember your login details and changes
      you make, this helps us to make Service personal oriented.
      <h4>How long do we store cookies?</h4>
      The storage period of the cookies depends on the type of cookie. For
      example, a session cookie will no longer be on your device after you have
      stopped surfing. Persistent cookies, on the other hand, remain on your
      device even after you stop browsing, until they expire or you delete them.
      <h4>Third-Party Providers</h4>
      <h4>
        Below you will find a detailed description of the integrated data
        services:
      </h4>
      <ol>
        <li>
          FirebaseAnalytics (analytics platform), FirebaseDatabase (user
          progress storage)
          https://firebase.google.com/docs/ios/app-store-data-collection#firebasedatabase,
          FirebaseCrashlytics (analytics and debugging of the application)
          https://firebase.google.com/docs/ios/app-store-data-collection#firebasecrashlytics,
          FirebaseRemoteConfig (Application functionality);
        </li>
        <li>
          Users can opt out of certain types of tracking by Google Analytics. If
          you do not want to participate in Google Analytics, you can download a
          browser add-on that blocks the display of ads. We may use remarketing
          technology like Google Remarketing. We may use Google Remarketing to
          serve targeted advertisements. You can disable this function by using
          the appropriate settings on the website http:
          //www.google.com/settings/ads.
        </li>

        <li>Recurly (a payment gateway) https://recurly.com/legal/privacy/;</li>
        <li>
          FacebookSDK (analytics platform)
          https://opensource.fb.com/legal/privacy
        </li>
      </ol>
      Further information about cookies, including how to see what cookies have
      been set on your computer or mobile device and how to manage and delete
      them, visit www.allaboutcookies.org and www.youronlinechoices.com.
      <h4>How can you control the cookie preferences ?</h4>
      Should you decide to change your preferences later through your browsing
      session, you can click on the “Privacy & Cookie Policy” tab on your
      screen. This will display the consent notice again enabling you to change
      your preferences or withdraw your consent entirely.
      <br />
      <br />
      In addition to this, different browsers provide different methods to block
      and delete cookies used by websites. You can change the settings of your
      browser to block/delete the cookies. To find out more about how to manage
      and delete cookies, visit wikipedia.org, www.allaboutcookies.org.
      <h4>CONTACT US</h4>
      If you have an enquiry regarding our Cookie Policy surrounding personal
      information, you can contact us at{' '}
      <a href={`mailto:${APP_CONSTANTS.EMAIL}`}>{APP_CONSTANTS.EMAIL}</a>.
      <br />
      <br />
      {APP_CONSTANTS.APP_NAME}
      <br />
      <br />
      Address: {APP_CONSTANTS.ADDRESS},
      <br />
      <br />
      Email: <a href={`mailto:${APP_CONSTANTS.EMAIL}`}>{APP_CONSTANTS.EMAIL}</a>
    </div>
  );
};

export default CookiePolicy;
